import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDDsafwavU7kiiRYTikGCcGnGyOnzR2eJg",
  authDomain: "hackernewsai.firebaseapp.com",
  projectId: "hackernewsai",
  storageBucket: "hackernewsai.appspot.com",
  messagingSenderId: "134049135838",
  appId: "1:134049135838:web:ee2f95ac26c7d81f0e564f",
  measurementId: "G-CKWRKLT973",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a Firestore instance
const db = getFirestore(app);

export { db };