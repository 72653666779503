// getHostname.js

// Helper function for getting hostname from URL
function getHostname(url) {
    try {
      const urlObj = new URL(url);
      const hostname = urlObj.hostname;
      const domainParts = hostname.split('.');
      const domain = domainParts.slice(-2).join('.');
      return domain;
    } catch (e) {
      return '';
    }
  }

export default getHostname;