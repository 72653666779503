import { createTheme } from '@mui/material/styles';

// Define the colors
const primaryMain = (isDark) => isDark ? '#90caf9' : '#1976d2';
const secondaryMain = (isDark) => isDark ? '#f48fb1' : '#dc004e';
const backgroundColor = (isDark) => isDark ? '#424242' : '#ffffff';
const textGrey = (isDark) => isDark ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)';
const textColor = (isDark) => isDark ? '#ffffff' : 'rgba(0, 0, 0, 0.87)';
const boxShadowColor = (isDark) => isDark ? 'inset 0px -1px 1px RGB(41, 41, 41)' : 'inset 0px -1px 1px #E7EBF0';
const chipBackgroundColor = (isDark) => isDark ? 'rgba(15, 92, 46, 0.5)' : 'rgba(198, 246, 217, 0.5)';

const getTheme = (isDark) => createTheme({
  palette: {
    mode: isDark ? 'dark' : 'light',
    primary: {
      main: primaryMain(isDark),
    },
    secondary: {
      main: secondaryMain(isDark),
    },
  },
  typography: {
    storyHeading: {
      fontSize: '0.875rem',
      lineHeight: '1.0rem',
    },
    storyDetails: {
      fontSize: '0.75rem',
      color: textGrey(isDark) + '!important',
      '& a': {
        textDecoration: 'none',
        color: textGrey(isDark),
      },
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (max-width: 600px)': {
            padding: '0',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: backgroundColor(isDark),
          color: textColor(isDark),
          minHeight: '48px',
          boxShadow: boxShadowColor(isDark),
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          lineHeight: '0.825rem',
          padding: '0.625rem',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.css-AIchip': {
            backgroundColor: chipBackgroundColor(isDark),
            border: '1px solid rgb(106, 231, 156)',
            color: 'rgb(106, 231, 156)',
            padding: '0px !important',
            fontSize: '0.725rem',
            fontWeight: '600',
            letterSpacing: '0.04rem',
            height: '17px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          display: 'block',
          margin: '0 auto', // Centers the button horizontally
          marginTop: '20px', // Add margin from the top if needed
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          marginRight: '0.25em', // Add margin from the top if needed
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '& span.domainTag': {
            color: textGrey(isDark),
          },
          '&.bodyStrong': {
            fontSize: '1rem',
            fontWeight: 'bold',
          },
          '& .storyDetailDiv': {
            marginTop: '0.425rem',
            display: 'block !important',
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#f44336',
        },
      },
    },
  },
});

export default getTheme;
