// Helper function for getting client IP
async function getClientIP() {
    const response = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
    const text = await response.text();
    const data = text.trim().split('\n').reduce((obj, pair) => {
      pair = pair.split('=');
      return { ...obj, [pair[0]]: pair[1] };
    }, {});
    return data.ip;
  }

export default getClientIP;