import * as React from 'react';
import { CssBaseline, Container, Chip, Box, AppBar, Toolbar, Typography, IconButton, SvgIcon, Switch, FormControlLabel } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import StoryList from './StoryList';

// Import the theme from the theme.js file
import getTheme from './theme';

function App() {
  const [isDark, setIsDark] = React.useState(true);
  const [isAIEnabled, setIsAIEnabled] = React.useState(true);

  const handleThemeChange = () => {
    setIsDark(!isDark);
  };

  const handleAISwitchChange = () => {
    setIsAIEnabled(!isAIEnabled);
  };

  const theme = getTheme(isDark);

  const iconPath = isDark 
    ? "M12 9c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3 1.35-3 3-3m0-2c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zM2 13h2c.55 0 1-.45 1-1s-.45-1-1-1H2c-.55 0-1 .45-1 1s.45 1 1 1zm18 0h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1zM11 2v2c0 .55.45 1 1 1s1-.45 1-1V2c0-.55-.45-1-1-1s-1 .45-1 1zm0 18v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1zM5.99 4.58c-.39-.39-1.03-.39-1.41 0-.39.39-.39 1.03 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0s.39-1.03 0-1.41L5.99 4.58zm12.37 12.37c-.39-.39-1.03-.39-1.41 0-.39.39-.39 1.03 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0 .39-.39.39-1.03 0-1.41l-1.06-1.06zm1.06-10.96c.39-.39.39-1.03 0-1.41-.39-.39-1.03-.39-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06zM7.05 18.36c.39-.39.39-1.03 0-1.41-.39-.39-1.03-.39-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06z"
    : "M9.37 5.51c-.18.64-.27 1.31-.27 1.99 0 4.08 3.32 7.4 7.4 7.4.68 0 1.35-.09 1.99-.27C17.45 17.19 14.93 19 12 19c-3.86 0-7-3.14-7-7 0-2.93 1.81-5.45 4.37-6.49zM12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9c0-.46-.04-.92-.10-1.36-.98 1.37-2.58 2.26-4.4 2.26-2.98 0-5.4-2.42-5.4-5.4 0-1.81.89-3.42 2.26-4.4-.44-.06-.9-.1-1.36-.1z";

  return (
<ThemeProvider theme={theme}>
  <CssBaseline />
  <AppBar position="static">
    <Toolbar>
      <Typography variant="h6" component="div">
        Hacker News{' '}
        <Chip label="AI" className="css-AIchip" size="medium"/>
      </Typography>
      <Box sx={{ flexGrow: 1 }} /> {/* This component takes up all available space, pushing the next components to the right */}
      <Box>
        <IconButton color="inherit" aria-label="Toggle theme" onClick={handleThemeChange}>
          <SvgIcon>
            <path d={iconPath} />
          </SvgIcon>
        </IconButton>
        <FormControlLabel
          control={<Switch checked={isAIEnabled} onChange={handleAISwitchChange} />}
          label="AI"
        />
        {/* You can add more buttons or components here */}
      </Box>
    </Toolbar>
  </AppBar>
      <Container maxWidth="md">
        <Box my={2}>
          <StoryList isAIEnabled={isAIEnabled} />
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;
