import React, { useState, useEffect } from 'react';

const LoadStoriesFromJson = () => {
  const [top24hrsAI, setTop24hrsAI] = useState([]);
  const [top24hrs, setTop24hrs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let urlAI;
  let urlTop24hrs;

  let env_type;
  env_type = process.env.NODE_ENV;
  env_type = "production"; //overide for testing

  if (env_type === 'development') {
    urlAI = 'http://localhost:3000/dev/v0/ai/topstories.json';
    urlTop24hrs = 'http://localhost:3000/dev/v0/topstories.json';
  } else {
    urlAI = 'https://storage.googleapis.com/hackernewsai.appspot.com/v0/ai/topstories.json';
    urlTop24hrs = 'https://storage.googleapis.com/hackernewsai.appspot.com/v0/topstories.json';
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseAI = await fetch(urlAI);
        const dataAI = await responseAI.json();
        setTop24hrsAI(dataAI);

        const responseTop24hrs = await fetch(urlTop24hrs);
        const dataTop24hrs = await responseTop24hrs.json();
        setTop24hrs(dataTop24hrs);

        setIsLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { top24hrsAI, top24hrs, isLoading };
};

export default LoadStoriesFromJson;
