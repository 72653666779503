import React, { useEffect, useState } from 'react';
import { collection, doc, getDoc, getDocs, addDoc } from 'firebase/firestore';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Dialog, DialogTitle, DialogContent, DialogActions, Button, Paper, Snackbar, LinearProgress } from '@mui/material';
import { db } from './firebase';
import getClientIP from './helpers/getClientIP';
import getHostname from './helpers/getHostname';
import useStoriesFromJson from './helpers/useStoriesJson';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

function StoryEvaluationDialog({ open, onClose, story, handleReport }) {
  const compositeScore = story?.ML_good - story?.ML_bad;
  const convertedScore = (compositeScore + 1) * 50;
  const isScoreAvailable = !isNaN(compositeScore);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Story Evaluation</DialogTitle>
      <DialogContent>
        {isScoreAvailable ? (
          <>
            <Typography variant="body1">Bad Score: {story?.ML_bad}</Typography>
            <Typography variant="body1">Good Score: {story?.ML_good}</Typography>
            <Typography variant="body1">
              Composite Score: {compositeScore.toFixed(3) || 'N/A'} ({Math.round(convertedScore)}%)
            </Typography>
            <Box sx={{ width: '100%', marginBottom: 2 }}>
              <LinearProgress variant="determinate" value={convertedScore} />
            </Box>
            <Typography variant="body1">
              Please evaluate this story. If the score does not seem accurate, you can mark it for rescoring. Your evaluation helps improve our model.
            </Typography>
          </>
        ) : (
          <Typography variant="body1">This story has not yet been scored by the training model.</Typography>
        )}
      </DialogContent>
      <DialogActions>
          <Button onClick={() => handleReport('BAD', story?.id)} color="primary">Mark as Bad</Button>
          <Button onClick={() => handleReport('GOOD', story?.id)} color="primary">Mark as Good</Button>
      </DialogActions>
    </Dialog>
  );
}

function StoryList({ isAIEnabled }) {
  const { top24hrsAI, top24hrs, isLoading } = useStoriesFromJson();
  const [displayedStoryIDs, setDisplayedStoryIDs] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentStory, setCurrentStory] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  let selected_ids = [];

  useEffect(() => {
    if (!isLoading) {
      selected_ids = isAIEnabled ? top24hrsAI : top24hrs
      setDisplayedStoryIDs(selected_ids);
    }
  }, [isAIEnabled, top24hrsAI, top24hrs, isLoading]);

  const handleOpenDialog = (story) => {
    setCurrentStory(story);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleReport = async (type, storyId) => {
    const clientIP = await getClientIP();
    const reportsCollection = collection(db, 'reports');
    const docRef = await addDoc(reportsCollection, {
      story: storyId,
      ip: clientIP,
      report: type,
      date: new Date().toISOString(),
    });
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setSnackbarOpen(true);
    }
    setDialogOpen(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {displayedStoryIDs.map((story) => (
                <TableRow key={story.id}>
                  <TableCell>
                    <a href={story.url} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Typography variant="storyHeading">{story.title} {getHostname(story.url) && <span className="domainTag">({getHostname(story.url)})</span>}</Typography>
                    </a>
                    <Typography variant="storyDetails" color="text.secondary">
                      <div className="storyDetailDiv">{story.score} points | {formatDistanceToNow(story.time * 1000)} ago | <a href="#" onClick={() => handleOpenDialog(story)}>report</a></div>
                    </Typography>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <StoryEvaluationDialog 
        open={dialogOpen} 
        onClose={handleClose} 
        handleReport={handleReport}
        story={currentStory}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message="Success! Model will retrain."
      />
    </div>
  );
}

export default StoryList;